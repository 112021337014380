import React from 'react'
import { string } from 'prop-types'
import PaymentSvg from '@shared/svgs/paymentSvgComp'
import { SectionHeader, SectionWrapper } from '@components/store-details/styles'
import GenesisLogo from '@shared/svgs/genesis-logo'

/*
Kids, Adult (showroom), Patio (aka outdoor) will have the main payment types
Outlet will have the condensed version
Express doesn’t need one (they're all puerto rico stores and they have their own website)
Distribution centers don’t take payments, its just a place to pick up

Note: there shouldn’t be any “outdoor” stores, they should all have been changed to the patio heading
so if you see any active outdoor stores, its wrong
*/

const PaymentOptions = ({ storeType }) => {
  if (storeType) {
    let paymentLogosForStoreType = []
    let paymentTypeText
    switch (storeType) {
      case 'Kids':
      case 'Showroom':
      case 'Patio':
        paymentLogosForStoreType = ['discover', 'mastercard', 'visa', 'amex', 'rtg', 'genesis']
        paymentTypeText = 'Discover, Master Card, Visa, American Express, Rooms To Go Credit, Genesis Credit'
        break
      case 'Outlet':
        paymentLogosForStoreType = ['discover', 'mastercard', 'visa', 'amex']
        paymentTypeText = 'Discover, Master Card, Visa, American Express'
        break
      default:
    }
    if (paymentLogosForStoreType.length === 0) return null
    return (
      <SectionWrapper>
        <SectionHeader>Accepted Payment Types</SectionHeader>
        <p>{paymentTypeText}</p>
        <PaymentSvg uniqueNameForId="paymentOptions" height="65px" cards={paymentLogosForStoreType} className="cell" />
        <GenesisLogo />
      </SectionWrapper>
    )
  }
  return null
}

PaymentOptions.propTypes = {
  storeType: string,
}

export default PaymentOptions
